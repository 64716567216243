import { Info } from "phosphor-react";
import { ChangeEvent, FocusEvent, InputHTMLAttributes } from "react";

export type InputProps = {
  name?: string;
  className?: string;
  register?: any;
  errors?: any;
  label?: string;
  onKeyUp?: any;
  typeOfInputfield?: any;
  placeholder?: string;
  disabled?: boolean;
  autofocus?: boolean;
  defaultValue?: string | number;
  value?: string | number;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (e: FocusEvent<HTMLInputElement>) => void;
  onFocus?: (e: FocusEvent<HTMLInputElement>) => void;
  containerClassName?: string;
  labelClassName?: string;
  error?: string | boolean;
  labelInfo?: string;
  maxLength?: number;
  variant?: "underlined" | "contained" | "borderless";
  adornment?: string;
  min?: number;
  max?: number;
};

const Input: React.FC<InputProps> = ({
  label,
  register = {},
  name = "",
  errors,
  typeOfInputfield,
  onKeyUp,
  placeholder,
  disabled = false,
  autofocus = false,
  value,
  onChange,
  onBlur,
  onFocus,
  className,
  defaultValue,
  containerClassName,
  labelClassName,
  error,
  labelInfo = "",
  maxLength,
  variant = "contained",
  adornment,
  min,
  max,
}) => {
  const variantClassName =
    variant === "underlined"
      ? "border-0 border-b border-holocene-blue rounded-none pt-2 pb-[9px] text-holocene-blue placeholder-holocene-blue"
      : variant === "borderless"
      ? "border-0 !bg-transparent "
      : "border focus:shadow-outline-blue rounded-md py-2";
  return (
    <div className={"w-full " + containerClassName}>
      {label && (
        <label
          htmlFor={name}
          className={`block w-full text-xs text-secondary-text font-semibold flex mb-1 ${labelClassName}`}
        >
          <span>{label}</span>
          {labelInfo && (
            <span>
              <Info className="ml-1" data-tip={labelInfo} size={16} />
            </span>
          )}
        </label>
      )}
      <div>
        {typeOfInputfield === "textarea" ? (
          <textarea
            id={name}
            name={name}
            type={typeOfInputfield}
            onKeyUp={onKeyUp}
            placeholder={placeholder}
            disabled={disabled}
            className={`block w-full px-3 ${
              disabled && "opacity-50"
            } ${className} placeholder-gray-400 transition duration-150 ease-in-out ${variantClassName} ${
              errors?.[name] || error
                ? "border-danger focus:border-danger"
                : "border-strokes focus:border-holocene-blue"
            } appearance-none focus:outline-none focus:ring-0 sm:text-sm sm:leading-5`}
            {...register}
            value={value === undefined ? register.value : value}
            onChange={onChange || register.onChange}
            onBlur={onBlur || register.onBlur}
            autoFocus={autofocus}
            defaultValue={defaultValue}
          />
        ) : (
          <div className={adornment ? "relative w-full" : ""}>
            <input
              id={name}
              name={name}
              type={typeOfInputfield}
              onKeyUp={onKeyUp}
              placeholder={placeholder}
              disabled={disabled}
              className={`break-all rounded-md block w-full px-3 ${variantClassName} ${
                disabled && "opacity-50"
              } ${className} placeholder-gray-400 transition duration-150 ease-in-out ${
                (errors?.[name] || error)
                  ? "border-danger focus:border-danger"
                  : "border-strokes focus:border-holocene-blue"
              } appearance-none focus:outline-none focus:ring-0 sm:text-sm sm:leading-5`}
              {...register}
              autoFocus={autofocus}
              defaultValue={defaultValue}
              value={value === undefined ? register.value : value}
              onChange={onChange || register.onChange}
              onBlur={onBlur || register.onBlur}
              onFocus={onFocus}
              maxLength={maxLength}
              min={min}
              max={max}
            />
            {adornment && (
              <div className="absolute inset-y-0 right-5 flex items-center pr-3">
                <span className="text-gray-500">{adornment}</span>
              </div>
            )}
          </div>
        )}

        {errors && errors[name] && (
          <div className="mt-1 text-xs text-danger">{errors[name]?.message}</div>
        )}
        {error && (
          <div className="mt-1 text-xs text-danger overflow-hidden text-ellipsis">{error}</div>
        )}
      </div>
    </div>
  );
};

interface InputWithIconProps extends InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  error?: string;
  icon?: JSX.Element;
  placeholderClass?: string;
  inputClassName?: string;
}

export const InputWithIcon = ({
  label,
  disabled,
  className,
  error,
  icon,
  placeholderClass = "",
  inputClassName,
  ...props
}: InputWithIconProps) => (
  <div className={className}>
    {label && (
      <label
        htmlFor={props.name}
        className="block w-full text-xs text-secondary-text font-semibold mb-1"
      >
        {label}
      </label>
    )}
    <div className="relative">
      <input
        {...props}
        className={`rounded-md block w-full px-3 py-2 ${
          disabled ? "bg-gray-100" : ""
        } placeholder-gray-400 ${placeholderClass} transition duration-150 ease-in-out border ${
          error ? "border-danger focus:border-danger" : "border-strokes focus:border-holocene-blue"
        } ${
          icon ? "pr-8" : ""
        } rounded-md appearance-none focus:outline-none focus:shadow-outline-blue focus:ring-0 sm:text-sm sm:leading-5 ${inputClassName}`}
      />
      {icon && <div className="absolute top-1/2 right-0 pr-3 -translate-y-1/2">{icon}</div>}
    </div>
    {error && <div className="mt-2 text-xs text-danger">{error}</div>}
  </div>
);

export default Input;
