const updateArrayElement = (array: any[], index: number, payload: any) => [
  ...array.slice(0, index),
  { ...array[index], ...payload },
  ...array.slice(index + 1),
];

const handlePlural = (prefix: number, word: string, postfix: string = "s") => {
  const truePostFix = prefix > 1 ? postfix : "";
  return `${word}${truePostFix}`;
};

const getKeyByValue = (object: any, value: any) => {
  return Object.keys(object).find((key) => object[key] === value);
};

export const createQueryStringFromFlatObject = (object?: any) => {
  if (!object) return "";
  return Object.entries(object)
    .filter(([_, value]) => value !== undefined && value !== null)
    .map(([key, value]) => `${key}=${value}`)
    .join("&");
};

const getDecimalPrecision = (num: number, precision: number) => {
  return num.toFixed(precision);
};

const printImage = async (imageSrc: string) => {
  const print = (await import("print-js")).default;
  print(imageSrc, "image");
};

const printPDF = async (pdfSrc: string) => {
  const print = (await import("print-js")).default;
  print(pdfSrc);
};

const getMimeTypeFromArrayBuffer = (arrayBuffer: Uint8Array) => {
  const uint8arr = new Uint8Array(arrayBuffer);

  const len = 4;
  if (uint8arr.length >= len) {
    let signatureArr = new Array(len);
    for (let i = 0; i < len; i++) signatureArr[i] = new Uint8Array(arrayBuffer)[i].toString(16);
    const signature = signatureArr.join("").toUpperCase();

    switch (signature) {
      case "89504E47":
        return "image/png";
      case "47494638":
        return "image/gif";
      case "25504446":
        return "application/pdf";
      case "FFD8FFDB":
      case "FFD8FFE0":
        return "image/jpeg";
      case "504B0304":
        return "application/zip";
      default:
        return null;
    }
  }
  return null;
};

export const getObjectValue = (object?: any, key?: string) => {
  if (!object || !key) return undefined;

  const keySplit = key.split(".");
  let value = object;
  keySplit.forEach((keyPart) => {
    if (value) {
      value = value[keyPart];
    }
  });

  return value;
};

const clearLocalStorage = () => {
  if (typeof window !== "undefined") {
    localStorage.removeItem("userid");
    localStorage.removeItem("activeOperation");
    localStorage.removeItem("userInfo");
  }
  // Commented this out - since we don't want to reset preferences even if another user logs in
  // localStorage.removeItem("filterDispatchCustomer");
  // localStorage.removeItem("deliveryDispatchIdentifier");
  // localStorage.removeItem("deliveryDispatchFilter");
  // localStorage.removeItem("filterCustomer");
  // localStorage.removeItem("deliveryIdentifier");
  // localStorage.removeItem("deliveryFilter");
};

export const getLocalStorageItem = (key: string) => {
  if (typeof window !== "undefined") {
    return localStorage.getItem(key);
  }
  return null;
};

const indexBy = <T>(arr: T[], keyfn: (currentIteration: T) => string) => {
  const grouped = arr.reduce((acc, current) => {
    const key = keyfn(current);
    acc[key] = current;
    return acc;
  }, {} as Record<string, T>);

  return grouped;
};

export const isDevelopmentOrStagingEnv = () =>
  ["staging", "development"].includes(process.env.ENV!);

function uniqueBy<T>(arr: T[], key: (item: T) => string) {
  const uniqueKeySet = new Set();
  return arr.filter((item) => {
    const itemKey = key(item);
    if (uniqueKeySet.has(itemKey)) {
      return false;
    } else {
      uniqueKeySet.add(itemKey);
      return true;
    }
  });
}

function swapArrayElements<T>(array: T[], index1: number, index2: number) {
  const copy = [...array];
  copy[index2] = array[index1];
  copy[index1] = array[index2];
  return copy;
}
export {
  handlePlural,
  updateArrayElement,
  getKeyByValue,
  getDecimalPrecision,
  printImage,
  printPDF,
  getMimeTypeFromArrayBuffer,
  clearLocalStorage,
  indexBy,
  uniqueBy,
  swapArrayElements,
};
