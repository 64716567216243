import { CircleNotch } from "phosphor-react";

export const LoadingSpinner = ({ size = 32 }: { size?: number }) => {
  return (
    <div className="flex justify-center items-center w-full h-full">
      <CircleNotch size={size} className="animate-spin text-holocene-blue" />
      <span className="sr-only">Loading...</span>
    </div>
  );
};

export const MaskedLoadingSpinner = () => {
  return (
    <div className="absolute bg-white opacity-50 flex justify-center items-center w-full h-full z-10">
      <CircleNotch size={32} className="animate-spin text-holocene-blue" />
      <span className="sr-only">Loading...</span>
    </div>
  );
};
