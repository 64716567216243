import { PurchaseSearchEnum } from "holocene-hooks/purchaseOrders.hooks";
import { ISearchResponse } from "holocene-pages/search";
import apiInstance from "holocene-services/axios";
import {
  IDeliveryDetailsWithoutType,
} from "holocene-services/delivery.service/types";
import { IDeliverySearch } from "holocene-services/inbound-delivery.service/types";
import { PurchaseOrderListing } from "holocene-services/purchase-orders.service/types";

class GlobalSearchService {
  getGlobalSearchByText(
    search: string,
    status: "pending" | "dispatched",
    { skip, take }: { skip: number; take: number }
  ) {
    return apiInstance
      .get(`/global-search?search=${search}&skip=${skip}&take=${take}&status=${status}`)
      .then(
        (res) =>
          res.data as {
            totalCount: number;
            results:
              | (ISearchResponse & { type: "shipment" })
              | (IDeliveryDetailsWithoutType & { type: "delivery" });
          }
      );
  }

  getPurchaseGlobalSearchByText(
    search: string,
    searchType: PurchaseSearchEnum,
    { skip, take }: { skip: number; take: number }
  ) {
    return apiInstance
    .get(`/global-search/purchase?search=${search}&skip=${skip}&take=${take}&searchType=${searchType}`)
    .then(
      (res) =>
        res.data as {
          totalCount: number;
          results: (PurchaseOrderListing & { searchType: PurchaseSearchEnum.purchaseOrder }) | (IDeliverySearch & {searchType: PurchaseSearchEnum.inboundDelivery});
        }
    );
  }
}

export const globalSearchService = new GlobalSearchService();
