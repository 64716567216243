import { QUERY_CONSTANTS } from "holocene-constants/queryConstants";
import { nylasService } from "holocene-services/nylas.service";
import { NylasMailThread } from "holocene-services/nylas.service/types";
import { useMemo } from "react";
import { useInfiniteQuery, useMutation, useQuery, useQueryClient } from "react-query";

interface INylasEmail {
  shipmentId: number | string | string[] | undefined;
  isActive?: boolean;
}

export const useGetNylasMails = ({ shipmentId, isActive }: INylasEmail) => {
  return useQuery(
    [QUERY_CONSTANTS.NYLAS_MAILS, shipmentId],
    () => nylasService.getNylasMails(0, 20, Number(shipmentId)),
    {
      enabled: !!isActive,
      keepPreviousData: true,
    }
  );
};

export const useGetNylasActive = () => {
  return useQuery([QUERY_CONSTANTS.NYLAS_ACTIVE], () =>
    nylasService.getNylasActive().then((res) => res.active)
  );
};

export const usePatchNylasMailsReadStatus = () => {
  const queryClient = useQueryClient();
  return useMutation(nylasService.patchNylasMailsReadStatus, {
    onSuccess: (_, variables) => {
      queryClient.setQueriesData(
        QUERY_CONSTANTS.NYLAS_MAILS,
        (oldData: { threads: NylasMailThread[] } | undefined) => {
          if (oldData) {
            return {
              ...oldData,
              threads: oldData.threads.map((thread) => {
                if (variables.nylasThreadIds.includes(thread.id)) {
                  return { ...thread, unread: variables.unread };
                } else {
                  return thread;
                }
              }),
            };
          }
          return { threads: [] };
        }
      );
      queryClient.setQueriesData(
        QUERY_CONSTANTS.INBOUND_DELIVERY_EMAILS,
        (oldData: { threads: NylasMailThread[] } | undefined) => {
          if (oldData) {
            return {
              ...oldData,
              threads: oldData.threads.map((thread) => {
                if (variables.nylasThreadIds.includes(thread.id)) {
                  return { ...thread, unread: variables.unread };
                } else {
                  return thread;
                }
              }),
            };
          }
          return { threads: [] };
        }
      );
    },
  });
};

export const usePostNylasMail = () => {
  return useMutation((paylaod: FormData) => nylasService.postNylasMail(paylaod));
};

export const useDeleteNylasMail = () => {
  return useMutation((paylod: string[]) => nylasService.deleteNylasMail(paylod));
};

export const useGetNylasMailsBySubjectInfinite = (subject?: string, withSummary?: boolean) => {
  const { data: isActive } = useGetNylasActive();

  const query = useInfiniteQuery(
    [QUERY_CONSTANTS.DELIVERY_NYLAS_MAILS, subject, withSummary],
    ({ pageParam }) =>
      nylasService.getEmailsBySubject({
        subject: subject!,
        nextCursor: pageParam?.nextCursor,
        withSummary,
      }),
    {
      enabled: !!isActive && !!subject,
      keepPreviousData: true,
    }
  );

  const queryData = useMemo(() => {
    const lastPage = query.data?.pages[query.data?.pages.length - 1];
    const firstPage = query.data?.pages[0];
    return {
      threads: query.data?.pages.flatMap((page) => page.threads),
      nextCursor: lastPage?.nextCursor,
      summary: firstPage?.summary,
      summaryDate: lastPage?.summaryDate,
    };
  }, [query.data]);

  const loadMore = queryData.nextCursor
    ? () => {
        query.fetchNextPage({ pageParam: { nextCursor: queryData.nextCursor } });
      }
    : undefined;

  return { ...query, data: queryData, loadMore };
};

export const useSendNylasMail = () => {
  return useMutation(nylasService.sendMail);
};
