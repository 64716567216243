/* eslint-disable @next/next/no-img-element */
import type { AppProps } from "next/app";
import { IconContext } from "phosphor-react";
import {
  FronteggThemeOptions,
  FronteggProviderNoSSR,
  useAuthActions,
  useAuthUser,
} from "@frontegg/nextjs";

import SidebarNew from "holocene-components/common/SidebarNew";
import {
  CountriesProvider,
  CountsProvider,
  NotificationProvider,
  TaskListProvider,
  UserDetailsProvider,
} from "holocene-providers/common";

import "../styles/globals.css";
import "../styles/modeOfTransport.scss";
import "../styles/reactDatePicker.scss";
import "../styles/reactPhoneNumberInput.scss";
import { RoutingLoader } from "holocene-components/common/RoutingLoader";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { DocumentUploadProvider } from "holocene-providers/shipments/DocumentUploadProvider";
import { GlobalSearchProvider } from "holocene-providers/common/GlobalSearch";
import Navbar from "holocene-components/common/Navbar";
import { DeliveryTaskListProvider } from "holocene-providers/common/DeliveryTaskListProvider";
import { ReactNode, useEffect } from "react";
import { SpeedInsights } from "@vercel/speed-insights/next";
import { ReauthinticationPrompt } from "holocene-components/nylas/ReacuthenticationPrompt";

const themeOptions: FronteggThemeOptions = {
  loginBox: {
    forgotPassword: {
      title: "",
      message: "Enter your email and we will send you a link to reset your password",
    },
    linkButtonTheme: {
      base: {
        color: "#0055FF!important",
        fontSize: "12px",
      },
    },
    submitButtonTheme: {
      base: {
        backgroundColor: "#0055FF",
      },
    },
    logo: {
      image:
        "https://fronteggprodeustorage.blob.core.windows.net/public-vendor-assets/3dcebe04-7894-4344-b0d9-4293eb2af37d%2Fassets%2Flogo-21e319dd-09dd-4d4c-a1cb-a04751bc16fb.png",
    },
    themeName: "modern",
    login: {
      title: "",
    },
    layout: {
      type: "float-left",
      sideElement: () => {
        return (
          <div
            style={{
              width: "50%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              position: "relative",
            }}
          >
            <div
              style={{
                borderLeft: "1px solid #D7D8D8",
                position: "absolute",
                left: "0",
                height: "40%",
              }}
            ></div>
            <img
              src="/login.png"
              style={{ width: "60%", maxWidth: "430px" }}
              alt="login page image"
            />
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                position: "fixed",
                bottom: 40,
                width: "100vw",
                left: 0,
                color: "#878A8A",
                fontSize: "14px",
              }}
            >
              ©{new Date().getFullYear()} HOLOCENE.EU{" "}
              <a
                href="mailto:help@©holocene.eu"
                style={{ color: "#0055FF", marginLeft: "6px", textDecoration: "none" }}
              >
                help@©holocene.eu
              </a>
            </div>
          </div>
        );
      },
      sideElementStyle: {
        width: "50%",
      },
    },
  },
};

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnMount: false,
      /**
       * We can configure individual queries to modify the stale time if required.
       * With staleTime 30000 (5 mins), the queries will only be refetched after 5 mins.
       */
      staleTime: 30000,
      refetchOnWindowFocus: false,
    },
  },
});

// On frontegg, if a user has a master tenant as the active tenant, all API calls made with the master
// tenant token fail. Hence we need to switch the user to a non master tenant before rendering the rest
// of the app
const FronteggMasterTenantSwitchGuard = ({ children }: { children: JSX.Element }) => {
  const { switchTenant } = useAuthActions();
  const user = useAuthUser();

  const isMasterTenant = user.tenantId?.toLowerCase().includes("master");

  useEffect(() => {
    if (isMasterTenant && user.tenantIds.length > 1) {
      localStorage.removeItem("userInfo");
      const actualTenantId = user.tenantIds.find(
        (id: string) => !id.toLowerCase().includes("master")
      );
      if (actualTenantId) {
        switchTenant({ tenantId: actualTenantId });
      }
    }
  }, [isMasterTenant, user]); // eslint-disable-line

  if (isMasterTenant) return null;

  return children;
};

function MyApp({ Component, pageProps, router }: AppProps) {
  if (["/shares/[token]", "/shares/delivery/[token]"].includes((router as any).state?.pathname)) {
    return (
      <QueryClientProvider client={queryClient}>
        <CountriesProvider>
          <IconContext.Provider value={{ weight: "regular", size: 24 }}>
            <div className="min-h-[calc(100vh_-_56px)] flex relative z-0">
              <Component {...pageProps} />
            </div>
            <SpeedInsights />
          </IconContext.Provider>
        </CountriesProvider>
      </QueryClientProvider>
    );
  }

  const contextOptions = {
    baseUrl: process.env.FRONTEGG_BASE_URL || "",
    clientId: process.env.FRONTEGG_CLIENT_ID || "",
  };

  return (
    <FronteggProviderNoSSR contextOptions={contextOptions} themeOptions={themeOptions}>
      <FronteggMasterTenantSwitchGuard>
        <QueryClientProvider client={queryClient}>
          <IconContext.Provider value={{ weight: "regular", size: 24 }}>
            <CountsProvider>
              <CountriesProvider>
                <UserDetailsProvider>
                  <TaskListProvider>
                    <DeliveryTaskListProvider>
                      <DocumentUploadProvider>
                        <NotificationProvider>
                          <GlobalSearchProvider>
                            <ReauthinticationPrompt />
                            {!router.pathname.includes("/shares") && (
                              <Navbar hideSearch={router.pathname === "/search" || router.pathname === '/purchase/search'} />
                            )}
                            <div className="min-h-[calc(100vh_-_56px)] flex relative z-0">
                              <RoutingLoader />
                              {!router.pathname.includes("/shares") && <SidebarNew />}
                              <Component {...pageProps} />
                              <ReactQueryDevtools />
                            </div>
                            <SpeedInsights />
                          </GlobalSearchProvider>
                        </NotificationProvider>
                      </DocumentUploadProvider>
                    </DeliveryTaskListProvider>
                  </TaskListProvider>
                </UserDetailsProvider>
              </CountriesProvider>
            </CountsProvider>
          </IconContext.Provider>
        </QueryClientProvider>
      </FronteggMasterTenantSwitchGuard>
    </FronteggProviderNoSSR>
  );
}

export default MyApp;
